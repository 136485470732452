import {
  AtomicTransactConfiguration,
  AtomicTransactPlugin
} from 'packages/atomic-transact/types';
import { Atomic } from '@atomicfi/transact-javascript';

export const AtomicTransact = (
  args: AtomicTransactConfiguration
): { close: () => void } => Atomic.transact(args);

export interface WebAtomicTransactPluginCreator {
  (): AtomicTransactPlugin;
}

export const createWebAtomicTransactPlugin: WebAtomicTransactPluginCreator = () => {
  let timeoutId = 0;
  let instance: ReturnType<typeof Atomic.transact> | undefined = undefined;
  return {
    open(args, onTimeout?) {
      if (instance !== undefined) return;
      timeoutId = window.setTimeout(() => {
        instance?.close();
        instance = undefined;
        timeoutId = 0;
        onTimeout && onTimeout();
      }, 1000 * 15);
      instance = Atomic.transact({
        ...args,
        onClose: ((data) => {
          instance = undefined;
          return args.onClose && args.onClose(data);
        }) as AtomicTransactConfiguration['onClose'],
        onFinish: ((data) => {
          instance = undefined;
          return args.onFinish && args.onFinish(data);
        }) as AtomicTransactConfiguration['onFinish'],
        onInteraction: ((interaction) => {
          if (timeoutId) {
            window.clearTimeout(timeoutId);
            timeoutId = 0;
          }
          return args.onInteraction && args.onInteraction(interaction);
        }) as AtomicTransactConfiguration['onInteraction']
      });
      return instance;
    }
  };
};
